import React from 'react';
import StockInternalUse from './StockInternalUse';
import KidstaleProductsStockForSale from './KidstaleProductsStockForSale';
import SwingSetsProducts from './SwingSetsProducts';
import KidstaleItems from './KidstaleItems';
import FloatingSidebar from '../components/sidebar/FloatingSidebar';

const StockSheetDashboard = () => {
  const sections = [
    { id: 'stockInternalUse', label: 'Stock Internal Use' },
    { id: 'kidstaleProductsStock', label: 'Kidstale Products' },
    { id: 'swingSetsProducts', label: 'Swing Sets' },
  ];

  return (
    <>
      {/* <FloatingSidebar sections={sections} /> */}
      <br />

      <div className="stockSheetDashboard" id='ttn' /* style={{ marginLeft: '60px' }} */> {/* Adjust margin as needed */}
        {/* <div id="stockInternalUse" className="margin-b-xlg pad-b-xlg">
          <h5 className='margin-b-md'>Stock Internal Use</h5> */}
          <StockInternalUse access={['admin', 'ops']}/>
        {/* </div>
        <div id="kidstaleProductsStock" className="margin-t-xlg margin-b-xlg pad-b-xlg">
          <h5 className='margin-b-md'>Kidstale Products Stock</h5> */}
          <KidstaleProductsStockForSale access={['admin', 'ops']} />
        {/* </div> */}
        {/* <div id="swingSetsProducts" className='margin-t-xlg  pad-b-xlg'>
          <h5 className='margin-b-md'>Swing Sets Products</h5> */}
          <SwingSetsProducts access={['admin', 'ops']}/>
          <KidstaleItems access={['admin', 'ops']}/>
        {/* </div> */}
      </div>
    </>
  );
};

export default StockSheetDashboard;

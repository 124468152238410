import React, { useEffect, useState } from 'react';
import config from '../config'; // Ensure you have your configuration with the API's base URL
import authHeaders from '../utilities/authHeaders';
import FloatingSidebar from '../components/sidebar/FloatingSidebar';
import LineChart from '../components/charts/LineChart';
import BarChart from '../components/charts/BarChart';
import GenericTable from '../components/table/GenericTable';

const OverviewDataDashboard = () => {
  const [invoiceTrend, setInvoiceTrend] = useState([]); 
  const [sumOfInvoice, setSumOfInvoice] = useState([]);
  const [soTrend, setSoTrend] = useState([]);
  const [sumOfSo, setSumOfSo] = useState([]);
  const [stockUnitsShipped, setStockUnitsShipped] = useState([]);
  const [weeklyInvoiceAmount, setWeeklyInvoiceAmount] = useState([]);
  const [ytdInvoiceAmount, setYtdInvoiceAmount] = useState([]);
  const [previousYearInvoiceAmount, setPreviousYearInvoiceAmount] = useState([]);
  const [ytdSoAmount, setYtdSoAmount] = useState([]);
  const [previousYearSoAmount, setPreviousYearSoAmount] = useState([]);
  const [overdueInvoiceAmount, setOverdueInvoiceAmount] = useState([]);

  const sections = [
    { id: 'invoiceTrend', label: 'Invoice Trend' },
    { id: 'sumOfInvoice', label: 'Sum of Invoice' },
    { id: 'soTrend', label: 'SO Trend' },
    { id: 'sumOfSo', label: 'Sum of SO' },
    { id: 'stockUnitsShipped', label: 'Stock Units Shipped' },
    { id: 'weeklyInvoiceAmount', label: 'Weekly Invoice Amount' },
  ];

  useEffect(() => {
    try {
        const fetchInvoiceTrendData = async () => {
          try {
            const headers = authHeaders(); // Assuming authHeaders function returns necessary authentication headers
            const response = await fetch(`${config.base_api}/zoho/invoiceTrend`, { method: 'GET', headers });

            if (!response.ok) throw new Error('Failed to fetch stock sheet data');

            let fetchedData = await response.json();

            fetchedData[0]?.chart_data[0].unshift(["Feb"]);
            fetchedData[0]?.chart_data[0].unshift(["Jan"]);

            setInvoiceTrend(fetchedData[0]?.chart_data);
          } catch (error) {
            console.error('Error fetching stock sheet data:', error);
            // Handle error, e.g., set error state, show toast notification, etc.
          }
        };

        const fetchSumOfInvoiceData = async () => {
          try {
            const headers = authHeaders(); // Assuming authHeaders function returns necessary authentication headers
            const response = await fetch(`${config.base_api}/zoho/sumOfInvoice`, { method: 'GET', headers }); 

            if (!response.ok) throw new Error('Failed to fetch stock sheet data');

            const fetchedData = await response.json();

            setSumOfInvoice(fetchedData[0]?.chart_data);
          } catch (error) {
            console.error('Error fetching stock sheet data:', error);
            // Handle error, e.g., set error state, show toast notification, etc.
          }
        };

        const fetchSoTrendData = async () => {
          try {
            const headers = authHeaders(); // Assuming authHeaders function returns necessary authentication headers
            const response = await fetch(`${config.base_api}/zoho/soTrend`, { method: 'GET', headers });

            if (!response.ok) throw new Error('Failed to fetch stock sheet data');

            const fetchedData = await response.json();

            setSoTrend(fetchedData[0]?.chart_data);
          } catch (error) {
            console.error('Error fetching stock sheet data:', error);
            // Handle error, e.g., set error state, show toast notification, etc.
          }
        };

        const fetchSumOfSoData = async () => {
          try {
            const headers = authHeaders(); // Assuming authHeaders function returns necessary authentication headers
            const response = await fetch(`${config.base_api}/zoho/sumOfSo`, { method: 'GET', headers });

            if (!response.ok) throw new Error('Failed to fetch stock sheet data');

            const fetchedData = await response.json();

            setSumOfSo(fetchedData[0]?.chart_data);
          } catch (error) {
            console.error('Error fetching stock sheet data:', error);
            // Handle error, e.g., set error state, show toast notification, etc.
          }
        };

        const fetchStockUnitsShippedData = async () => {
          try {
            const headers = authHeaders(); // Assuming authHeaders function returns necessary authentication headers
            const response = await fetch(`${config.base_api}/zoho/stockUnitsShipped`, { method: 'GET', headers });

            if (!response.ok) throw new Error('Failed to fetch stock sheet data');

            let fetchedData = await response.json();

            fetchedData[0]?.chart_data[0].unshift(["Feb"]);
            fetchedData[0]?.chart_data[0].unshift(["Jan"]);

            setStockUnitsShipped(fetchedData[0]?.chart_data);
          } catch (error) {
            console.error('Error fetching stock sheet data:', error);
            // Handle error, e.g., set error state, show toast notification, etc.
          }
        };

        const fetchWeeklyInvoiceAmountData = async () => {
          try {
            const headers = authHeaders(); // Assuming authHeaders function returns necessary authentication headers
            const response = await fetch(`${config.base_api}/zoho/weeklyInvoiceAmount`, { method: 'GET', headers });

            if (!response.ok) throw new Error('Failed to fetch stock sheet data');

            const fetchData = await response.json();
            let transformData = [];

            for (let i = 0; i < 52; i++) {
              let weekData = {};
              weekData = { 'week': fetchData[0]?.chart_data[i]?.n?.fv };
              const lastIndex = fetchData[0]?.chart_data[i]?.v?.length - 1;
              fetchData[0]?.chart_data[i]?.v?.map((year, index) => {
                if (index === lastIndex) weekData['sum'] = year?.fv;
                else weekData[`year${2020 + index + 1}`] = year?.fv;
              }, {});
              transformData.push(weekData);
            }

            setWeeklyInvoiceAmount(transformData);
          } catch (error) {
            console.error('Error fetching stock sheet data:', error);
            // Handle error, e.g., set error state, show toast notification, etc.
          }
        };

        const fetchYtdInvoiceAmount = async () => {
          try {
            const headers = authHeaders(); // Assuming authHeaders function returns necessary authentication headers
            const response = await fetch(`${config.base_api}/zoho/ytdInvoiceAmount`, { method: 'GET', headers });

            if (!response.ok) throw new Error('Failed to fetch stock sheet data');

            const fetchedData = await response.json();

            setYtdInvoiceAmount(fetchedData[0]?.chart_data[0]?.value);
          } catch (error) {
            console.error('Error fetching stock sheet data:', error);
            // Handle error, e.g., set error state, show toast notification, etc.
          }
        };

        const fetchPreviousYearInvoiceAmount = async () => {
          try {
            const headers = authHeaders(); // Assuming authHeaders function returns necessary authentication headers
            const response = await fetch(`${config.base_api}/zoho/previousYearInvoiceAmount`, { method: 'GET', headers });

            if (!response.ok) throw new Error('Failed to fetch stock sheet data');

            const fetchedData = await response.json();

            setPreviousYearInvoiceAmount(fetchedData[0]?.chart_data[0]?.value);
          } catch (error) {
            console.error('Error fetching stock sheet data:', error);
            // Handle error, e.g., set error state, show toast notification, etc.
          }
        };

        const fetchYtdSoAmount = async () => {
          try {
            const headers = authHeaders(); // Assuming authHeaders function returns necessary authentication headers
            const response = await fetch(`${config.base_api}/zoho/ytdSoAmount`, { method: 'GET', headers });

            if (!response.ok) throw new Error('Failed to fetch stock sheet data');

            const fetchedData = await response.json();

            setYtdSoAmount(fetchedData[0]?.chart_data[0]?.value);
          } catch (error) {
            console.error('Error fetching stock sheet data:', error);
            // Handle error, e.g., set error state, show toast notification, etc.
          }
        };

        const fetchPreviousYearSoAmount = async () => {
          try {
            const headers = authHeaders(); // Assuming authHeaders function returns necessary authentication headers
            const response = await fetch(`${config.base_api}/zoho/previousYearSoAmount`, { method: 'GET', headers });

            if (!response.ok) throw new Error('Failed to fetch stock sheet data');

            const fetchedData = await response.json();

            setPreviousYearSoAmount(fetchedData[0]?.chart_data[0]?.value);
          } catch (error) {
            console.error('Error fetching stock sheet data:', error);
            // Handle error, e.g., set error state, show toast notification, etc.
          }
        };

        const fetchOverdueInvoiceAmount = async () => {
          try {
            const headers = authHeaders(); // Assuming authHeaders function returns necessary authentication headers
            const response = await fetch(`${config.base_api}/zoho/overdueInvoiceAmount`, { method: 'GET', headers });

            if (!response.ok) throw new Error('Failed to fetch stock sheet data');

            const fetchedData = await response.json();

            setOverdueInvoiceAmount(fetchedData[0]?.chart_data[0]?.value);
          } catch (error) {
            console.error('Error fetching stock sheet data:', error);
            // Handle error, e.g., set error state, show toast notification, etc.
          }
        };

        fetchInvoiceTrendData();
        fetchSumOfInvoiceData();
        fetchSoTrendData();
        fetchSumOfSoData();
        fetchStockUnitsShippedData();
        fetchWeeklyInvoiceAmountData();
        fetchYtdInvoiceAmount();
        fetchPreviousYearInvoiceAmount();
        fetchYtdSoAmount();
        fetchPreviousYearSoAmount();
        fetchOverdueInvoiceAmount();
    } catch (error) {
      console.error(error);
    }

  }, []);

  const colorSet = { 
    0: 'rgb(110,144,237)',
    1: 'rgb(104,224,200)',
    2: 'rgb(240,117,132)',
    3: 'rgb(244,158,87)', 
  };

  const columns = [
    {key: 'week', label: 'Week'},
    ...Array.from({ length: new Date().getFullYear() - 2020 }, (_, i) => {
      const year = 2021 + i;
      return { key: `year${year}`, label: `Year ${year}` };
    }),
    {key: 'sum', label: 'Sum'}
  ];

  return (
    <>
      <FloatingSidebar sections={sections} />

      <div class="dashboard-cards-container">
        <div class="dashboard-card">
          <div class="card-title margin-b-md">YTD Invoice Amount</div>
          <div class="card-number">{ytdInvoiceAmount}</div>
        </div>
        
        <div class="dashboard-card">
          <div class="card-title margin-b-md">Previous Year Invoice Amount</div>
          <div class="card-number">{previousYearInvoiceAmount}</div>
        </div>
        
        <div class="dashboard-card">
          <div class="card-title margin-b-md">YTD SO Amount</div>
          <div class="card-number">{ytdSoAmount}</div>
        </div>
        
        <div class="dashboard-card">
          <div class="card-title margin-b-md">Previous Year SO Amount</div>
          <div class="card-number">{previousYearSoAmount}</div>
        </div>
        
        <div class="dashboard-card">
          <div class="card-title margin-b-md">Overdue Invoice Amount</div>
          <div class="card-number">{overdueInvoiceAmount}</div>
        </div>
      </div>

      <div className="" id='ttn' style={{ marginLeft: '60px' }}> {/* Adjust margin as needed */}
        <div id="invoiceTrend" className="margin-b-xlg pad-b-xlg width-100">
          <h5 className='margin-b-md'>Invoice Trend</h5>
          <LineChart apiData={invoiceTrend} xLabel={'Month'} yLabel={'Total amount of invoice each month'} seriesQuantifier={2021} seriesColorSet={colorSet} />
        </div>
        <div id="sumOfInvoice" className="margin-t-xlg margin-b-xlg pad-b-xlg">
          <h5 className='margin-b-md'>Sum of Invoice</h5>
          <BarChart apiData={sumOfInvoice} barOrientation={"x"} xLabel={'Quarter'} seriesQuantifier={2021} yLabel={'Total amount of invoice placed each quarter'} 
            seriesColorSet={colorSet} />
        </div>
        <div id="soTrend" className='margin-t-xlg  pad-b-xlg'>
          <h5 className='margin-b-md'>SO Trend</h5>
          <LineChart apiData={soTrend} xLabel={'Month'} yLabel={'Total amount of SO each month'} seriesQuantifier={2021} seriesColorSet={colorSet} />
        </div>
        <div id="sumOfSo" className='margin-t-xlg  pad-b-xlg'>
          <h5 className='margin-b-md'>Sum of SO</h5>
          <BarChart apiData={sumOfSo} barOrientation={"x"} xLabel={'Quarter'} yLabel={'Total amount of SO placed each quarter'} seriesLabel={""} 
            seriesQuantifier={2021} seriesColorSet={colorSet} />
        </div>
        <div id="stockUnitsShipped" className='margin-t-xlg  pad-b-xlg'>
          <h5 className='margin-b-md'>Stock Units Shipped</h5>
          <BarChart apiData={stockUnitsShipped} barOrientation={"x"} xLabel={'Month'} yLabel={'Total amount of stock units shipped each month'}
            seriesQuantifier={2021} seriesColorSet={colorSet} />
        </div>
        <div id="weeklyInvoiceAmount" className='margin-t-xlg  pad-b-xlg'>
          <h5 className='margin-b-md'>Weekly Invoice Amount</h5>
          <GenericTable data={weeklyInvoiceAmount} columns={columns} noAction={true}/>
        </div>
      </div>
    </>
  );
};

export default OverviewDataDashboard;

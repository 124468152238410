import React, { useEffect, useState } from 'react';
import config from '../config';
import authHeaders from '../utilities/authHeaders';
import Btn from '../components/Btn';
import { useStore } from '../StoreProvider';
import { ACTIONS } from '../Actions';
import convertJSON from '../utilities/convertJSON';
import LoadingInline from '../components/LoadingInline';

const Reports = () => {
    const { state, dispatch } = useStore();
    const headers = authHeaders(state?.auth.accessToken, state?.auth.refreshToken);
    const [reportType, setReportType] = useState();
    const [beginDate, setBeginDate] = useState();
    const [endDate, setEndDate] = useState();
    const [ladDate, setLadDate] = useState(new Date().toISOString().split('T')[0]);
    const [disabled, setDisabled] = useState(true);
    const [year, setYear] = useState(new Date().getFullYear());
    const [years, setYears] = useState([]);
    const [downloading, setDownloading] = useState(false);
    const [openSections, setOpenSections] = useState([]);
    const [reportList, setReportList] = useState([]);

    const pathname = window.location.pathname;

    if (pathname === '/operations/reports' && state?.auth.username !== "mcooke@playgroundboss.com") {
        window.location.pathname = '/';
    }

    useEffect(() => {
        const currentYear = new Date().getFullYear();
        const startYear = 2020;
        const yearsArray = [];
        for (let year = startYear; year <= currentYear; year++) {
            yearsArray.push(year);
        }
        setYears(yearsArray);
    }, []);

    useEffect(() => {
        async function fetchReportData() {
            try {
                const response = await fetch(`${config.base_api}/reports`, { headers });
                const data = await convertJSON(response);

                if (data && data.length > 0) {
                    data.sort((a, b) => a.id - b.id);
                    setReportList(data);
                }
            } catch (error) {
                console.log(error);
            }
        }
        fetchReportData();
    }, []);

    useEffect(() => {
        const enabledReportTypes = [
            "lad_projects",
            "quickbook_pos",
            "quickbook_customers",
            "customers_with_tax_exemption",
            "all_open_projects",
            "all_closed_projects",
            "all_projects",
            "accounting_ltd_po"
        ];

        if (reportType === "quoted_products") {
            setDisabled(!(beginDate && endDate && reportType));
        } else if (enabledReportTypes.includes(reportType)) {
            setDisabled(false);
        } else {
            setDisabled(true);
        }
    }, [reportType, beginDate, endDate]);

    const toggleAccordion = (index) => {
        setOpenSections((prev) =>
            prev.includes(index) ? prev.filter((i) => i !== index) : [...prev, index]
        );
    }

    const getFormattedDate = () => {
        const date = new Date();

        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();

        const formattedDate = `${month}-${day}-${year}`;
        return formattedDate;
    }

    const downloadFile = (data) => {
        const blob = new Blob([data], { type: 'text/csv' });
        const url = window.URL.createObjectURL(blob)
        const a = document.createElement('a')
        a.setAttribute('href', url)

        let fileName = "";

        if (reportType === "quoted_products") {
            fileName = `quoted_products_report_${beginDate}_to_${endDate}.csv` || 'report_download.csv';
        } else if (reportType === "lad_projects") {
            fileName = `lad_projects_report_${ladDate}.csv` || 'report_download.csv';
        } else if (reportType === "quickbook_pos") {
            fileName = `quickbook_pos_report_${year}.csv` || 'report_download.csv';
        } else if (reportType === "quickbook_customers") {
            fileName = `quickbook_customers_report_${getFormattedDate()}.csv` || 'report_download.csv';
        } else if (reportType === "customers_with_tax_exemption") {
            fileName = `customers_with_tax_exemption_report_${getFormattedDate()}.csv` || 'report_download.csv';
        } else if (reportType === "all_open_projects") {
            fileName = `all_open_projects_report_${getFormattedDate()}.csv` || 'report_download.csv';
        } else if (reportType === "all_closed_projects") {
            fileName = `all_closed_projects_report_${getFormattedDate()}.csv` || 'report_download.csv';
        } else if (reportType === "all_projects") {
            fileName = `all_projects_report_${getFormattedDate()}.csv` || 'report_download.csv';
        } else if (reportType === "accounting_ltd_po") {
            fileName = `accounting_ltd_po_report_${getFormattedDate()}.csv` || 'report_download.csv';
        } else {
            fileName = 'report_download.csv';
        }

        a.setAttribute('download', fileName);
        a.click()
    }

    const generateLADProjectsCSV = (data) => {
        const csvRows = [];
        const headers = ['Project ID', 'Project Name', 'Project Owner', 'Days since Last Contact', 'Date of Last Contact'];
        const values = [];
        const today = new Date(ladDate);

        data.sort((a, b) => new Date(a.last_activity) - new Date(b.last_activity)).forEach(project => {
            const lastActivityDate = new Date(project.last_activity);
            const differenceInMilliseconds = today.getTime() - lastActivityDate.getTime();
            const differenceInDays = Math.floor(differenceInMilliseconds / (1000 * 3600 * 24));
            values.push([project.project_id, project.project_name, project.owner || "Unassigned", differenceInDays - 1, project.last_activity].join(','));
        });

        csvRows.push(headers);
        csvRows.push(values.join('\n'));

        return csvRows.join('\n');
    }

    function cleanString(str) {
        if (!str && typeof str !== 'string') {
            return '';
        }
        let cleanStr = '';
        try {
            cleanStr = str?.replaceAll(/[\t\n\r]+/g, '');
        } catch (error) {
            console.log(error);
            cleanStr = str.replace(/[\t\n\r]+/g, '');
        }
        return cleanStr;
    }

    function csvFormat(field) {
        if (field.includes(',') || field.includes('"')) {
            field = field.replace(/"/g, '""');
            field = `"${field}"`;
        }
        return field;
    }

    const generateQuickbookPOSCSV = (data) => {
        const csvRows = [];
        const headers = ['Id', 'Project Number', 'Customer', 'State', 'County', 'City', 'Zip', 'Vendor', 'PO Number', 'Product Name', 'Item Description', 'Cost', 'Sale Price'];
        const values = [];

        data?.sort((a, b) => b?.project_number?.localeCompare(a?.project_number));

        data?.forEach(project => {
            if (project.id == '37807') {
                console.log('stop')
            }
            const newRow = [
                project.id || "",
                csvFormat(cleanString(project.project_number)) || "",
                csvFormat(cleanString(project.customer_name)) || "",
                csvFormat(cleanString(project.state)) || "",
                project.county || "",
                csvFormat(cleanString(project.city)) || "",
                csvFormat(cleanString(project.zip + "")) || "",
                csvFormat(cleanString(project.vendor)) || "",
                csvFormat(cleanString(project.po_number)) || "",
                csvFormat(cleanString(project.product_name)) || "",
                csvFormat(cleanString(project?.item_description)),
                project.cost,
                project.resale_price
            ].join(',') || "";
            values.push(newRow);
        });

        csvRows.push(headers);
        csvRows.push(values.join('\n'));

        return csvRows.join('\n');
    }

    const generateQuickbookCustomersCSV = (data, taxExemptionOnly = false) => {
        const csvRows = [];
        let headers = ['Id', 'Display Name', 'First Name', 'Last Name', 'Email', 'Phone', 'Tax Exemption', 'Tax Exemption Link'];
        if (taxExemptionOnly) {
            headers = ['Customer Id', 'Project Id', 'Quote Number', 'Display Name', 'First Name', 'Last Name', 'Email', 'Phone', 'Tax Exemption Link'];
        }
        const values = [];

        data?.sort((a, b) => a?.FullyQualifiedName?.localeCompare(b?.FullyQualifiedName));

        data?.forEach(customer => {
            const newRow = [];
            newRow.push(customer.Id || "");
            if (taxExemptionOnly) {
                newRow.push(csvFormat(cleanString(customer.projectId)) || "");
                newRow.push(csvFormat(cleanString(customer.quoteNumber)) || "");
            }
            newRow.push(csvFormat(cleanString(customer?.FullyQualifiedName || "")) || "");
            newRow.push(csvFormat(cleanString(customer?.GivenName || "")) || "");
            newRow.push(csvFormat(cleanString(customer?.FamilyName || "")) || "");
            newRow.push(csvFormat(cleanString(customer?.PrimaryEmailAddr?.Address || "")) || "");
            newRow.push(csvFormat(cleanString(customer?.PrimaryPhone?.FreeFormNumber || "")) || "");
            if (!taxExemptionOnly) {
                newRow.push((customer.taxExemption ? "Yes" : "") || "");
            }
            newRow.push(csvFormat(cleanString(customer?.taxExemptionLink || "")) || "");
            values.push(newRow.join(','));
        });

        csvRows.push(headers);
        csvRows.push(values.join('\n'));

        return csvRows.join('\n');
    }

    const generateQuotedProductsCSV = (data) => {
        const csvRows = [];
        const categoryHeaders = ['Category', 'Total Quoted', 'Total Sold'];
        const productHeaders = ['Category', 'Product SKU', 'Product Name', 'Total Quoted', 'Total Sold'];
        const categoryValues = [];
        const productValues = [];
        const categoryOrder = [];

        Object.values(data[0]).sort((a, b) => b.quoted_total - a.quoted_total).forEach(cat => {
            categoryValues.push([cat.name, cat.quoted_total, cat.sold_total].join(','));
            categoryOrder.push(cat.id);
        });

        categoryOrder.forEach(catId => {
            Object.values(data[1])
                .filter((prod) => prod.categoryId === catId)
                .sort((a, b) => b.quoted_qty - a.quoted_qty)
                .forEach(prod => {
                    productValues.push([data[0][prod.categoryId].name, prod.sku, prod.name, prod.quoted_qty, prod.sold_qty].join(','))
                });
        });

        csvRows.push(categoryHeaders);
        csvRows.push(categoryValues.join('\n'));
        csvRows.push('')
        csvRows.push(productHeaders);
        csvRows.push(productValues.join('\n'));
        return csvRows.join('\n');
    }

    const generateOpenOrClosedProjectsCSV = (data) => {
        const csvRows = [];
        const headers = [
            'Project Status',
            'Project ID',
            'Project Name',
            'Date Bought',
            'Owner',
            'Contact',
            'Preferred Completion Date',
            'Has Installation?',
            'Installation Date',
            'Payment Status',
            'Balance Due',
            'KidsTale PO ID',
            'KidsTale PO Status',
            'Total KidsTale PO Amount'
        ];
        const values = [];

        data?.sort((a, b) => new Date(b?.date_bought) - new Date(a?.date_bought));

        data?.forEach(project => {
            const projectStatus = (project?.complete === true || project?.cancelled === true) ? "Closed" : "Open";
            const dateBought = new Date(project?.date_bought).toISOString().split('T')[0];
            const preferredCompletion = project?.preferred_completion ? new Date(project.preferred_completion).toISOString().split('T')[0] : "";
            const projectInstallDate = project?.install_date ? new Date(project.install_date).toLocaleDateString('en-US') : "";
            const poInstallDates = project?.Purchase_orders
                ?.filter(po => po.install_date)
                .map(po => {
                    const formattedDate = new Date(po.install_date).toLocaleDateString('en-US');
                    return `${formattedDate} (PO ${po.qb_po_id || "No ID"})`;
                })
                .join(', ');
            const hasInstallation = project?.has_installation ? "Yes" : "";
            const installDate = [projectInstallDate, poInstallDates].filter(Boolean).join(', ');
            const paymentStatus = project?.all_invoices_paid ? "Paid" : "Unpaid";
            const invoiceBalance = paymentStatus === "Paid"
                ? ""
                : (project?.invoice_balance != null && project.invoice_balance !== '' && !isNaN(project.invoice_balance))
                    ? `$${Number(project.invoice_balance).toFixed(2)}`
                    : "$0.00";
            const kidsTalePOs = project?.Purchase_orders?.filter(po => po.project_name && po.project_name.includes("KidsTale")) || [];
            let POStatus = "";
            if (kidsTalePOs.length === 1) {
                POStatus = kidsTalePOs[0]?.POStatus || "Unknown";
            } else if (kidsTalePOs.length > 1) {
                POStatus = kidsTalePOs
                    .map(po => `${po.POStatus || "Unknown"} (PO ${po.qb_po_id || "No ID"})`)
                    .join(', ');
            }
            if (kidsTalePOs.length > 0 && !POStatus) {
                POStatus = "Unknown";
            }
            let totalPOAmount = 0;
            if (kidsTalePOs.length > 0) {
                totalPOAmount = kidsTalePOs
                    .reduce((sum, po) => sum + (po.TotalAmt || 0), 0);
            }
            const formattedTotalPOAmount = totalPOAmount > 0 ? `$${totalPOAmount.toFixed(2)}` : "";

            const newRow = [
                csvFormat(cleanString(projectStatus)) || "",
                csvFormat(cleanString(project?.project_id)) || "",
                csvFormat(cleanString(project?.project_name)) || "",
                csvFormat(cleanString(dateBought)) || "",
                csvFormat(cleanString(project?.owner)) || "",
                csvFormat(cleanString(project?.contact)) || "",
                csvFormat(cleanString(preferredCompletion)) || "",
                csvFormat(cleanString(hasInstallation)) || "",
                csvFormat(cleanString(installDate)) || "",
                csvFormat(cleanString(paymentStatus)) || "",
                csvFormat(cleanString(invoiceBalance)) || "",
                csvFormat(cleanString(kidsTalePOs.map(po => po.qb_po_id).join(', '))) || "",
                csvFormat(cleanString(POStatus)) || "",
                csvFormat(cleanString(formattedTotalPOAmount)) || ""
            ].join(',') || "";
            values.push(newRow);
        });

        csvRows.push(headers);
        csvRows.push(values.join('\n'));

        return csvRows.join('\n');
    }

    const generateAccountingLtdPOCSV = (data) => {
        const csvRows = [];
        const headers = [
            "PO ID",
            "Project Number",
            "Project ID",
            "PO Manufacturer",
            "PO Cost",
            "Yes/No Install",
            "Install Date",
            "Project Completion Date",
            "Contact Name",
            "Contact Phone Number",
            "Contact Email",
            "Shipping City",
            "Shipping State",
            "Project Owner",
            "Sales Person"
        ]
        const values = [];
        data?.sort((a, b) => new Date(b?.createdAt) - new Date(a?.createdAt));

        data?.forEach(po => {
            const poID = String(po?.qb_po_id) || "";
            const projectNumber = po?.project_number || "";
            const projectID = po?.project_id || "";
            const poManufacturer = po?.vendor || "";
            const poCost = (po?.cost && !isNaN(po.cost) && po.cost > 0)
                ? `$${po.cost.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`
                : "$0.00";
            const yesNoInstall = (po?.install_date || po?.type === "installation") ? "Yes" : "No";
            const installDate = po?.install_date ? new Date(po.install_date).toLocaleDateString('en-US') : "";
            const projectCompletionDate = po?.Project?.preferred_completion ? po.Project.preferred_completion.slice(0,10) : "";
            const contactName = po?.relatedQuote?.firstname && po?.relatedQuote?.lastname
                ? `${po.relatedQuote.firstname} ${po.relatedQuote.lastname}`
                : "";
            const phoneNumber = String(po?.relatedQuote?.phone || "");
            const email = po?.relatedQuote?.email || "";
            const shippingCity = po?.relatedQuote?.shipping_city || "";
            const shippingState = po?.relatedQuote?.shipping_state || "";
            const projectOwner = po?.Project?.owner || "";
            const salesPerson = po?.relatedQuote?.prepared_by_name || "";

            const newRow = [
                csvFormat(cleanString(poID)) || "",
                csvFormat(cleanString(projectNumber)) || "",
                csvFormat(cleanString(projectID)) || "",
                csvFormat(cleanString(poManufacturer)) || "",
                csvFormat(cleanString(poCost)) || "",
                csvFormat(cleanString(yesNoInstall)) || "",
                csvFormat(cleanString(installDate)) || "",
                csvFormat(cleanString(projectCompletionDate)) || "",
                csvFormat(cleanString(contactName)) || "",
                csvFormat(cleanString(phoneNumber)) || "",
                csvFormat(cleanString(email)) || "",
                csvFormat(cleanString(shippingCity)) || "",
                csvFormat(cleanString(shippingState)) || "",
                csvFormat(cleanString(projectOwner)) || "",
                csvFormat(cleanString(salesPerson)) || "",
            ].join(',') || "";
            values.push(newRow);
        });

        csvRows.push(headers);
        csvRows.push(values.join('\n'));

        return csvRows.join('\n');
    }

    const makeCSV = (data) => {
        let csvFileData;

        if (reportType === "quoted_products") {
            csvFileData = generateQuotedProductsCSV(data);
        } else if (reportType === "lad_projects") {
            csvFileData = generateLADProjectsCSV(data);
        } else if (reportType === "quickbook_pos") {
            csvFileData = generateQuickbookPOSCSV(data);
        } else if (reportType === "quickbook_customers") {
            csvFileData = generateQuickbookCustomersCSV(data);
        } else if (reportType === "customers_with_tax_exemption") {
            csvFileData = generateQuickbookCustomersCSV(data, true);
        } else if (reportType === "all_open_projects" || reportType === "all_closed_projects" || reportType === "all_projects") {
            csvFileData = generateOpenOrClosedProjectsCSV(data);
        } else if (reportType === "accounting_ltd_po") {
            csvFileData = generateAccountingLtdPOCSV(data);
        }

        return csvFileData;
    }

    const exportCSV = async () => {
        const data = await generateReportData();
        if (!data || data.length < 2 || !Array.isArray(data)) {
            dispatch({ type: ACTIONS.TOAST, payload: { message: "Could not generate report", isError: true } });
            return null;
        }
        downloadFile(makeCSV(data));
    }

    const generateReportData = async () => {
        let routePath = "";
        let params = "";

        if (reportType === "quoted_products") {
            routePath = "products/generate/quotedProductsReport";
            params = `?dateStart=${beginDate}&dateEnd=${endDate}`;
        } else if (reportType === "lad_projects") {
            routePath = "projects/generate/LADReport";
            let today = new Date(ladDate);
            today = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 7);
            const sevenDaysAgo = today.toISOString().split('T')[0];
            params = `?date=${sevenDaysAgo}`;
        } else if (reportType === "quickbook_pos") {
            routePath = "quickbooks/syncProducts/authURI";
            params = `QuickbooksPurchaseOrderReport_|_${year}`;
        } else if (reportType === "quickbook_customers") {
            routePath = "quickbooks/syncProducts/authURI";
            params = 'QuickbooksCustomersReport';
        } else if (reportType === "customers_with_tax_exemption") {
            routePath = "quickbooks/syncProducts/authURI";
            params = 'QuickbooksCustomersReportAndTaxExemption';
        } else if (reportType === "all_open_projects") {
            routePath = "quickbooks/syncProducts/authURI";
            params = "allOpenProjects";
        } else if (reportType === "all_closed_projects") {
            routePath = "quickbooks/syncProducts/authURI";
            params = "allClosedProjects";
        } else if (reportType === "all_projects") {
            routePath = "quickbooks/syncProducts/authURI";
            params = "allProjects";
        } else if (reportType === "accounting_ltd_po") {
            routePath = "quickbooks/syncProducts/authURI";
            params = "accountingLtdPO";
        } else {
            dispatch({ type: ACTIONS.TOAST, payload: { message: "Could not generate report", isError: true } });
            return null;
        }

        try {
            setDownloading(true);
            const res = await fetch(`${config.base_api}/${routePath}/${params}`, { headers });
            const data = await convertJSON(res);
            setDownloading(false);
            return data;
        } catch (error) {
            setDownloading(false);
            dispatch({ type: ACTIONS.TOAST, payload: { message: error, isError: true } });
            console.log(error);
            return [];
        }
    }

    const quickbookPosDisplay = () => {
        return (
            <div className='pad-t-lg'>
                <div className='flex-row align-center'>
                    <div className="flex-column">
                        <label className='pad-r-sm' htmlFor="begin_date">Year:</label>
                        <select value={year} onChange={(e) => setYear(e.target.value)} className='pad-xs' disabled={downloading}>
                            {years.map(yr => (
                                <option key={yr} value={yr}>
                                    {yr}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>
            </div>
        )
    }

    const quotedProductsDisplay = () => {
        return (
            <div className='pad-t-lg'>
                <div className='flex-row align-center'>
                    <div className="flex-column">
                        <label className='pad-r-sm' htmlFor="begin_date">Begin Date:</label>
                        <input value={beginDate} onChange={(e) => setBeginDate(e.target.value)} disabled={downloading}
                            className='pad-xs' name="begin_date" type="date" />
                    </div>

                    <div className="flex-column margin-l-xlg">
                        <label className='pad-r-sm' htmlFor="end_date">End Date:</label>
                        <input value={endDate} onChange={(e) => setEndDate(e.target.value)} disabled={downloading}
                            className='pad-xs' name="end_date" type="date" />
                    </div>
                </div>
            </div>
        )
    }

    const reportParametersDisplay = () => {
        if (reportType === "quoted_products") return quotedProductsDisplay();
        else if (reportType === "quickbook_pos") return quickbookPosDisplay();
        else return null;
    }

    const exportClicked = () => {
        if (reportType === "quoted_products") {
            if (beginDate && endDate && beginDate > endDate) {
                dispatch({ type: ACTIONS.TOAST, payload: { message: 'End Date cannot precede Begin Date', isError: true } });
            } else {
                exportCSV();
            }
        }
        else if (reportType === "lad_projects") {
            if (ladDate) {
                exportCSV();
            } else {
                dispatch({ type: ACTIONS.TOAST, payload: { message: 'Date is blank', isError: true } });
            }
        } else if (reportType === "quickbook_pos") {
            if (!year) {
                dispatch({ type: ACTIONS.TOAST, payload: { message: 'Year is blank', isError: true } });
            } else {
                exportCSV();
            }
        } else if (
            reportType === "quickbook_customers" ||
            reportType === "customers_with_tax_exemption" ||
            reportType === "all_open_projects" ||
            reportType === "all_closed_projects" ||
            reportType === "all_projects" ||
            reportType === "accounting_ltd_po") {
            exportCSV();
        } else {
            let cause = 'Report Type';
            let message = cause ? `${cause} is blank` : 'Could not generate report';
            dispatch({ type: ACTIONS.TOAST, payload: { message, isError: true } });
        }
    }

    const handleReportTypeChange = (e) => {
        setReportType(e.target.value);
        const accordionIndex = e.target.selectedIndex - 1;
        setOpenSections((prev) => {
            return prev.includes(accordionIndex) ? prev : [...prev, accordionIndex];
        });
    }

    return (
        <div className='width-100 flex-column align-center justify-center'>
            <div className='width-fit-content pad-t-xlg'>
                <div className='flex-row width-100vw'>
                    <div className="width-55 flex-column align-center pad-t-xxxlg margin-t-xxlg">
                        <h1 className='text-center'>Reports</h1>
                        <div className='flex-column pad-t-lg width-20'>
                            <label className='pad-b-xs text-center' id='report_type' htmlFor='report_type'>
                                Select Report Type:
                            </label>
                            <select className='pad-xs' name="report_type" onChange={handleReportTypeChange} disabled={downloading}>
                                <option disabled="disabled" value={null} selected="selected">Select ...</option>
                                {reportList.map((report, index) => (
                                    <option key={index} value={report.report_type}>{report.title}</option>
                                ))}
                            </select>
                        </div>

                        {reportParametersDisplay()}

                        <div className='width-100 flex-row justify-center margin-t-xxlg'>
                            <Btn title="CSV Export"
                                btnClass="text-center pad-tb-md bg-light-green text-white text-bold b-none green-button box-shadow min-width-150px justify-center"
                                disabled={disabled || downloading}
                                onClick={(event) => {
                                    event.preventDefault();
                                    exportClicked();
                                }}
                            />
                        </div>
                    </div>
                    <div className="">
                        <h1 className=' text-center'>Report Description</h1>
                        <div className='shade-odd-rows width-600px margin-xlg'>
                            {reportList.map((report, index) => (
                                <table className='margin-lg'>
                                    <tbody>
                                        <tr className='pointer'
                                            onClick={() => toggleAccordion(index)}>
                                            <td className={`text-lrg ${openSections.includes(index) ? 'bold' : ''}`}>
                                                {report.title} <span>{openSections.includes(index) ? '-' : '+'}</span>
                                            </td>
                                        </tr>
                                        <tr className={`${openSections.includes(index) ? '' : 'hide'}`}>
                                            {openSections.includes(index) && <td className='text-lrg line-height-120'><b>Description:</b> {report.description}</td>}
                                        </tr>
                                        <tr className={`${openSections.includes(index) ? '' : 'hide'}`}>
                                            {openSections.includes(index) && <td className='text-lrg line-height-120'><b>Criteria:</b> {report.criteria}</td>}
                                        </tr>
                                        <tr className={`${openSections.includes(index) ? '' : 'hide'}`}>
                                            {openSections.includes(index) && (
                                                <td className="text-lrg">
                                                    <p className="text-bold">Columns: </p>
                                                    <div className="flex-column">
                                                        {report?.columns?.some((column) => column.source === 'Quote Tool') && (
                                                            <div className="flex-column width-100 margin-t-lg margin-l-xxxlg">
                                                                <p className="underline">Data from Quote Tool:</p>
                                                                <ul className='line-height-140'>
                                                                    {report?.columns
                                                                        ?.filter((column) => column.source === 'Quote Tool')
                                                                        .map((column, idx) => (
                                                                            <li key={`quote-tool-${idx}`}>
                                                                                {column.name}
                                                                                {column.item && (
                                                                                    <ul className="margin-l-xlg">
                                                                                        <li>Retrieved as {column.item} → {column.origin}</li>
                                                                                    </ul>
                                                                                )}
                                                                            </li>
                                                                        ))}
                                                                </ul>
                                                            </div>
                                                        )}
                                                        {report?.columns?.some((column) => column.source === 'Quickbooks') && (
                                                            <div className="flex-column width-100 margin-t-lg margin-l-xxxlg">
                                                                <p className="underline">Data from QuickBooks:</p>
                                                                <ul className='line-height-140'>
                                                                    {report?.columns
                                                                        ?.filter((column) => column.source === 'Quickbooks')
                                                                        .map((column, idx) => (
                                                                            <li key={`quickbooks-${idx}`}>
                                                                                {column.name}
                                                                                {column.item && (
                                                                                    <ul className="margin-l-xlg">
                                                                                        <li>Retrieved as {column.item} → {column.origin}</li>
                                                                                    </ul>
                                                                                )}
                                                                            </li>
                                                                        ))}
                                                                </ul>
                                                            </div>
                                                        )}
                                                        {report?.columns?.some((column) => column.source === 'Calculated') && (
                                                            <div className="flex-column width-100 margin-t-lg margin-l-xxxlg">
                                                                <p className="underline">Calculated Data:</p>
                                                                <ul className='line-height-140'>
                                                                    {report?.columns
                                                                        ?.filter((column) => column.source === 'Calculated')
                                                                        .map((column, idx) => (
                                                                            <li key={`calculated-${idx}`}>
                                                                                {column.name}
                                                                                {column.origin && (
                                                                                    <ul className="margin-l-xlg">
                                                                                        <li>{column.origin}</li>
                                                                                    </ul>
                                                                                )}
                                                                            </li>
                                                                        ))}
                                                                </ul>
                                                            </div>
                                                        )}
                                                    </div>
                                                </td>
                                            )}
                                        </tr>
                                    </tbody>
                                </table>
                            ))}
                        </div>
                    </div>
                </div>
                <div className="margin-t-lg">
                    {downloading && <LoadingInline />}
                </div>
            </div>
        </div>
    )
}

export default Reports

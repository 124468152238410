import React from 'react';
import { useState, useEffect } from 'react';
import config from '../../config';
import authHeaders from '../../utilities/authHeaders';
import formatDate from '../../utilities/formatDate';
import calendarIcon from '../../assets/feather-calendar.svg';
import xIcon from '../../assets/xicon.svg';
import LoadingInline from '../../components/LoadingInline';
import { useStore } from '../../StoreProvider';
import { ACTIONS } from '../../Actions';
import convertJSON from '../../utilities/convertJSON';

const InstallationStatus = ({ projectID, userDropdown, handleEmailSent, handleSaveInstallationDate, loading, loadingQuote, loadingQB, phoneExt, saveClicked, installationDateChanged, setInstallationDateChanged, leftCol }) => {
    const { state, dispatch } = useStore();

    const [showEmailModal, setShowEmailModal] = useState(false)
    const [inputInstallDate, setInputInstallDate] = useState("")
    const [installerArray, setInstallerArray] = useState([])
    const [vendorArray, setVendorArray] = useState([])
    const [vendorEmailArray, setVendorEmailArray] = useState([])
    const [pendingPOId, setPendingPOId] = useState("")
    const [pendingDate, setPendingDate] = useState("")
    const [installerEmailModal, setInstallerEmailModal] = useState("")
    const [noInstallerEmail, setNoInstallerEmail] = useState(false)
    const [sendToInstaller, setSendToInstaller] = useState(false)
    const [installerNameModal, setInstallerNameModal] = useState("")
    const [installerPOModal, setInstallerPOModal] = useState("")
    const [emailCustomer, setEmailCustomer] = useState(true)
    const [vendorBccField, setVendorBccField] = useState("")
    const [customerBccField, setCustomerBccField] = useState("")
    const [vendorEmailMessage, setVendorEmailMessage] = useState("")
    const [customerEmailMessage, setCustomerEmailMessage] = useState("")
    const [vendorEmailSent, setVendorEmailSent] = useState(false)
    const [customerEmailSent, setCustomerEmailSent] = useState(false)
    const [signature, setSignature] = useState("")
    const [dateInstallerId, setDateInstallerId] = useState("")

    let emailSignature = state?.quote?.prepared_by_name || "N/A"
    let contactName = state?.quote?.firstname + ' ' + state?.quote?.lastname || " "
    let customerEmail = state?.quote?.email || ""
    const headers = authHeaders(state?.auth.accessToken, state?.auth.refreshToken);

    const connectPOtable = async (poArray) => {
        let poDraftArray = []
        try {
            let POdata = await fetch(`${config.base_api}/projects/allPOs/${projectID}`, { headers });
            POdata = await convertJSON(POdata);
            for (let i = 0; i < POdata?.length; i++) {
                let id = POdata[i].id;
                let installDate = POdata[i]?.install_date || ""
                let installDateUpdated = POdata[i].install_date_updated
                let installBadgeDate = installDate

                for (let j = 0; j < poArray?.length; j++) {
                    if (poArray[j].id === id) {
                        let installerName = poArray[j].installerName
                        let installerNote = poArray[j].installerNote
                        let installerEmail = poArray[j].installerEmail
                        let installerPOId = poArray[j].installerPOId

                        poDraftArray.push({ id, installerName, installerNote, installerEmail, installDate, installerPOId, installDateUpdated, installBadgeDate })
                    }
                }
            }
            setInstallerArray(poDraftArray)
        } catch (err) {
            console.log(err);
        }
    }

    function parseSignatureTemplate(text, name, phone) {
        text = text?.replace(/SALES_REP_NAME/, name);
        text = text?.replace(/SALES_REP_EXTENSION/, phone);
        return text;
    }

    const getEmailSignature = async () => {
        try {
            const res = await fetch(`${config.base_api}/email`, { headers });
            const values = await convertJSON(res);
            const emailSignature = parseSignatureTemplate(values?.signature, state?.project?.owner, phoneExt || '');
            setSignature(emailSignature);
        } catch (error) {
            console.log(error);
            setSignature('');
        }
    }

    const connectVendorTable = async (manufacturerArray) => {
        let vendorDraftArray = []
        let vendorEmails = []
        try {
            let vendorData = await fetch(`${config.base_api}/manufacturers/`, { headers });
            vendorData = await convertJSON(vendorData);

            for (let i = 0; i < manufacturerArray?.length; i++) {
                let manufacturerName = manufacturerArray[i]
                for (let j = 0; j < vendorData?.length; j++) {
                    if (vendorData[j].vendor === manufacturerName) {
                        let vendorID = vendorData[j].id
                        let vendorEmail = vendorData[j].email
                        vendorDraftArray.push({ vendorID, vendorEmail, vendorName: manufacturerName })
                        vendorEmails.push(vendorEmail)
                    }
                }
            }
            setVendorArray(vendorDraftArray)
            setVendorEmailArray(vendorEmails)
        } catch (err) {
            console.log(err);
        }
    }

    useEffect(() => {
        if (installerArray?.length > 0) {
            let installDateArray = []
            for (let i = 0; i < installerArray?.length; i++) {
                let installationDateObject = {
                    installerId: installerArray[i].id,
                    installDate: installerArray[i]?.installDate.slice(0, 10) || ""
                }
                installDateArray.push(installationDateObject)
            }
            setInputInstallDate(installDateArray)
        } else {
            setNoInstallerEmail(true)
            setPendingDate(state?.project?.install_date?.slice(0, 10) || "")
            setInputInstallDate(state?.project?.install_date?.slice(0, 10) || "")
        }
    }, [installerArray, state?.project?.install_date])

    useEffect(() => {
        let poArray = []
        if (state?.qbPurchaseOrders?.installers?.length > 0) {
            for (let i = 0; i < state?.qbPurchaseOrders?.installers?.length; i++) {
                let installerName = state?.qbPurchaseOrders.installers[i].installer_name
                let id = state?.qbPurchaseOrders.installers[i].refID
                let installerNote = state?.qbPurchaseOrders.installers[i].note
                let installerEmail = state?.qbPurchaseOrders.installers[i].installer_email
                let installerPOId = state?.qbPurchaseOrders.installers[i].qb_po_id
                poArray.push({ id, installerName, installerNote, installerEmail, installerPOId })
            }
            connectPOtable(poArray);
        }
        let manufacturerArray = []
        if (state?.qbPurchaseOrders?.pos?.length > 0) {
            for (let i = 0; i < state?.qbPurchaseOrders?.pos?.length; i++) {
                let manufacturerName = state?.qbPurchaseOrders.pos[i].manufacturer_name
                manufacturerArray.push(manufacturerName)
            }
            connectVendorTable(manufacturerArray);
        }
    }, [state?.qbPurchaseOrders])

    useEffect(() => {
        if (state?.project?.owner) {
            getEmailSignature();
        }
        return () => {
            setSignature('');
        }
    }, [phoneExt, state?.project?.owner]);

    useEffect(() => {
        if (saveClicked && installationDateChanged) handleSaveDate();

    }, [saveClicked])

    const displayEmailModal = async (event) => {
        setShowEmailModal(true)
        let selectedMonth = new Date(event.target.value).getUTCMonth() + 1
        if (installerArray.length > 0) {
            setSendToInstaller(true)
        }

        let projectAddress = state?.quote.shipping_address + ", " + state?.quote.shipping_city + ", " + state?.quote.shipping_state + " " + state?.quote.shipping_zip

        if (event.target.id === state?.project.project_id) {
            let emailMessageDate = inputInstallDate.substr(5).replace(/-/g, "/") + "/" + inputInstallDate.substr(0, 4)

            setNoInstallerEmail(true)
            setPendingDate(inputInstallDate)

            setVendorEmailMessage(`This installation date has changed.  Please know that all shipments are to arrive on ${emailMessageDate}.`)
            setCustomerEmailMessage(`This installation date has changed.  Please know that all shipments are to arrive on ${emailMessageDate}.`)

            return
        }

        setPendingPOId(event.target.id)
        setShowEmailModal(true)

        for (let i = 0; i < installerArray.length; i++) {
            if (event.target.id == installerArray[i].id) {
                let emailMessageDate = inputInstallDate[i]?.installDate.substr(5).replace(/-/g, "/") + "/" + inputInstallDate[i]?.installDate.substr(0, 4)

                setNoInstallerEmail(false);
                setPendingDate(inputInstallDate[i]?.installDate)
                setInstallerEmailModal(installerArray[i].installerEmail)
                setInstallerNameModal(installerArray[i].installerName)
                setInstallerPOModal(installerArray[i].installerPOId)

                let additionalNote = installerArray[i]?.installerNote || ""

                setVendorEmailMessage(`This installation date has changed.  Please know that all shipments are to arrive on ${emailMessageDate} at the address: ${projectAddress}. ${additionalNote}`)
                setCustomerEmailMessage(`This installation date has changed.  Please know that all shipments are to arrive on ${emailMessageDate} at the address: ${projectAddress}. ${additionalNote}`)
            }
        }
    }

    function handleLeaveModal() {
        console.log("leaving modal")
        setShowEmailModal(false)
        setVendorBccField("")
        setCustomerBccField("")
        setVendorEmailSent(false)
        setCustomerEmailSent(false)
        setNoInstallerEmail(false)
    }

    const handleSendVendorEmail = async () => {
        setVendorEmailSent(true)
        let eventId = projectID?.replace("-", "") + installerPOModal + "v"
        let vendorEmailString = vendorEmailArray.toString()
        let vendorBccString = vendorEmailString.concat(',', vendorBccField)
        if (sendToInstaller === true) {
            vendorBccString = vendorBccString.concat(',', installerEmailModal)
        }

        let emailSubject = state?.project?.project_id + " " + state?.project.project_name + " --" + state?.quote.shipping_city + ", " + state?.quote.shipping_state
        let projectAddress = state?.quote.shipping_address + ", " + state?.quote.shipping_city + ", " + state?.quote.shipping_state + " " + state?.quote.shipping_zip

        let emailBody = {
            from: "ops@playgroundboss.com",
            to: "",
            bcc: vendorBccString,
            subject: emailSubject,
            body: vendorEmailMessage,
            date: pendingDate,
            eventId: eventId,
            signature: emailSignature,
            projectAddress: projectAddress,
            vendor: true,
            projectId: projectID,
        }

        try {
            const res = await fetch(`${config.base_api}/projects/sendEmail`, {
                headers: authHeaders(state?.auth.accessToken, state?.auth.refreshToken),
                method: 'POST',
                body: JSON.stringify(emailBody)
            });


            if (res.ok) {
                let response = await convertJSON(res);
                console.log('Email sent');

                dispatch({
                    type: ACTIONS.TOAST, payload: {
                        message: 'Email sent',
                        isError: false,
                    }
                });

                if (response.includes("failed")) {
                    dispatch({
                        type: ACTIONS.TOAST, payload: {
                            message: 'Google Calendar Invite failed to send',
                            isError: true,
                        }
                    });
                } else {
                    dispatch({
                        type: ACTIONS.TOAST, payload: {
                            message: 'Google Calendar Invite sent',
                            isError: false,
                        }
                    });
                }


                await storeInstallDate();

                if (customerEmailSent === true) handleLeaveModal();

                handleEmailSent();
            } else {
                setShowEmailModal(false)
                dispatch({
                    type: ACTIONS.TOAST, payload: {
                        message: 'Could not send email',
                        isError: true,
                    }
                });
            }
        } catch (error) {
            setVendorEmailSent(false)
            console.log(error);
            dispatch({
                type: ACTIONS.TOAST, payload: {
                    message: 'Could not send email - Check email addresses are correct',
                    isError: true,
                }
            });
        }
    }

    const handleSendCustomerEmail = async () => {
        setCustomerEmailSent(true);
        let eventId = projectID?.replace("-", "") + installerPOModal + "c"
        let emailTo = ""
        if (emailCustomer === true) {
            emailTo = customerEmail
        }

        let emailSubject = state?.project.project_id + " " + state?.project.project_name + " --" + state?.quote.shipping_city + ", " + state?.quote.shipping_state

        let projectAddress = state?.quote.shipping_address + ", " + state?.quote.shipping_city + ", " + state?.quote.shipping_state + " " + state?.quote.shipping_zip

        let emailBody = {
            from: "ops@playgroundboss.com",
            to: emailTo,
            bcc: customerBccField,
            subject: emailSubject,
            body: customerEmailMessage,
            date: pendingDate,
            eventId: eventId,
            projectAddress: projectAddress,
            signature: signature,
            projectId: projectID,
        }

        try {
            const res = await fetch(`${config.base_api}/projects/sendEmail`, {
                headers: authHeaders(state?.auth.accessToken, state?.auth.refreshToken),
                method: 'POST',
                body: JSON.stringify(emailBody)
            });

            if (res.ok) {
                let response = await convertJSON(res);
                console.log('Email sent');
                dispatch({
                    type: ACTIONS.TOAST, payload: {
                        message: 'Email sent',
                        isError: false,
                    }
                });


                if (response.includes("failed")) {
                    dispatch({
                        type: ACTIONS.TOAST, payload: {
                            message: 'Google Calendar Invite failed to send',
                            isError: true,
                        }
                    });
                } else {
                    dispatch({
                        type: ACTIONS.TOAST, payload: {
                            message: 'Google Calendar Invite sent',
                            isError: false,
                        }
                    });
                }

                await storeInstallDate();

                if (vendorEmailSent === true) handleLeaveModal();

                handleEmailSent();
            } else {
                setShowEmailModal(false)
                dispatch({
                    type: ACTIONS.TOAST, payload: {
                        message: 'Could not send email',
                        isError: true,
                    }
                });
            }
        } catch (error) {
            setCustomerEmailSent(false);
            console.log(error);
            dispatch({
                type: ACTIONS.TOAST, payload: {
                    message: 'Could not send email - Check email addresses are correct',
                    isError: true,
                }
            });
        }
    }

    const storeInstallDate = async () => {
        let savingInstallDate = pendingDate
        if (pendingDate === "") savingInstallDate = null;
        let storeInstallDate = {
            install_date: savingInstallDate,
            install_date_updated: new Date()
        }
        if (noInstallerEmail === true) {
            try {
                const res = await fetch(`${config.base_api}/projects/updateInstall/${state?.project.project_id}`, {
                    method: 'PATCH',
                    headers: authHeaders(state?.auth.accessToken, state?.auth.refreshToken),
                    body: JSON.stringify(storeInstallDate),
                })

                if (res.ok) {
                    let tempProject = state?.project

                    let day = new Date(pendingDate)
                    let nextDay = day.setDate(day.getDate() + 1)
                    let newDate = formatDate(nextDay);

                    tempProject.install_date = pendingDate
                    tempProject.install_date_updated = storeInstallDate.install_date_updated.toJSON();
                    tempProject.installBadgeDate = newDate;
                    dispatch({ type: ACTIONS.PROJECT, payload: tempProject });
                }
            } catch (error) {
                console.log(error)
            }
        } else {
            try {
                const res = await fetch(`${config.base_api}/projects/installPO/${pendingPOId}`, {
                    method: 'PATCH',
                    headers: authHeaders(state?.auth.accessToken, state?.auth.refreshToken),
                    body: JSON.stringify(storeInstallDate),
                });

                if (res.ok) {
                    let tempArray = installerArray
                    for (let i = 0; i < tempArray?.length; i++) {
                        if (tempArray[i].id === parseInt(pendingPOId)) {
                            let day = new Date(pendingDate)
                            let nextDay = day.setDate(day.getDate() + 1)
                            let newDate = formatDate(nextDay);

                            tempArray[i].installDate = pendingDate
                            tempArray[i].installDateUpdated = storeInstallDate.install_date_updated.toJSON()
                            tempArray[i].installBadgeDate = newDate
                            setInstallerArray(tempArray)

                            break;
                        }
                    }
                }
            } catch (error) {
                console.log(error)
            }
        }
    }

    function handleVendorBccField(event) {
        let BccField = event.target.value
        let domain = BccField.substr(BccField.length - 4)

        if (domain == ".com" || domain == ".net" || domain == ".org") {
            setVendorBccField(event.target.value + ",")
        } else {
            setVendorBccField(event.target.value)
        }
    }

    function handleCustomerBccField(event) {
        let BccField = event.target.value
        let domain = BccField.substr(BccField.length - 4)

        if (domain == ".com" || domain == ".net" || domain == ".org") {
            setCustomerBccField(event.target.value + ",")
        } else {
            setCustomerBccField(event.target.value)
        }
    }

    function handleVendorCheckbox(event) {
        if (event.target.checked === true) {
            setVendorEmailArray([...vendorEmailArray, event.target.value])
        } else {
            setVendorEmailArray(vendorEmailArray.filter(email => email !== event.target.value))
        }
    }

    function handleInstallerCheckbox() {
        setSendToInstaller(!sendToInstaller)
    }

    function handleCustomerCheckbox() {
        setEmailCustomer(!emailCustomer)
    }

    const convertTimeStamp = (timestamp) => {
        if (!timestamp) return '';
        const eventDate = new Date(timestamp);
        let hours = eventDate?.getHours();
        // the old timestamp was in UTC, it still has the right seconds with two digits
        let minutes = timestamp?.slice(14, 16)
        let am_pm = 'am'
        if (parseInt(hours) === 0) am_pm = 'pm';
        if (parseInt(hours) > 12) {
            hours = hours - 12;
            am_pm = 'pm'
        }
        if (hours[0] == 0) {
            hours = hours?.slice(1)
        }
        return `${hours}:${minutes} ${am_pm}`;
    };

    const handleSaveDate = async (event) => {
        if (installerArray?.length > 0) {
            let savedInstallDate = ''
            for (let i = 0; i < inputInstallDate?.length; i++) {
                if ((dateInstallerId == inputInstallDate[i].installerId) || (event.target.id == inputInstallDate[i].installerId)) {
                    console.log(inputInstallDate[i].installDate)
                    savedInstallDate = inputInstallDate[i].installDate
                }
            }
            if (savedInstallDate === "") savedInstallDate = null;
            let storeInstallDate = {
                install_date: savedInstallDate,
                install_date_updated: new Date()
            }
            try {
                const res = await fetch(`${config.base_api}/projects/installPO/${dateInstallerId}`, {
                    method: 'PATCH',
                    headers: authHeaders(state?.auth.accessToken, state?.auth.refreshToken),
                    body: JSON.stringify(storeInstallDate),
                });

                if (res.ok) {
                    let tempArray = installerArray
                    for (let i = 0; i < tempArray?.length; i++) {
                        if (tempArray[i].id === parseInt(dateInstallerId)) {
                            let day = new Date(savedInstallDate)
                            let nextDay = day.setDate(day.getDate() + 1)
                            let newDate = formatDate(nextDay);

                            tempArray[i].installDate = savedInstallDate
                            tempArray[i].installDateUpdated = storeInstallDate.install_date_updated.toJSON()
                            tempArray[i].installBadgeDate = newDate
                            setInstallerArray(tempArray)

                            break;
                        }
                    }
                }
            } catch (error) {
                console.log(error)
            }
            handleSaveInstallationDate();
            setInstallationDateChanged(false)
            dispatch({
                type: ACTIONS.TOAST, payload: {
                    message: 'Installation date saved.',
                    isError: false,
                }
            });
        } else {
            console.log(pendingDate)
            await storeInstallDate();

            handleSaveInstallationDate();
            setInstallationDateChanged(false)
            dispatch({
                type: ACTIONS.TOAST, payload: {
                    message: 'Installation date saved.',
                    isError: false,
                }
            });
        }
    }

    const handleDateChange = (event) => {
        setInstallationDateChanged(true)
        if (installerArray?.length > 0) {
            let tempArray = inputInstallDate.map(item => {
                if (event.target.id == item.installerId) {
                    return {
                        ...item,
                        installDate: event.target.value
                    }
                } else {
                    return item;
                }
            })
            setInputInstallDate(tempArray)
            setDateInstallerId(event.target.id)
        } else {
            setPendingDate(event.target.value)
            setInputInstallDate(event.target.value)
        }
    }

    if (loading || state?.loadingPO || loadingQB || loadingQuote) {
        return (
            <div className={`component-card height-325px overflow-scroll ${leftCol ? 'min-width-500px' : 'max-width-300px'}`}>
                <p className='text-xl text-bold pad-l-xxlg pad-t-lg pad-b-sm'>3. Installation Status</p>
                <LoadingInline />
            </div>
        )
    }

    return (
        <>
            <div className={`component-card height-325px overflow-scroll ${leftCol ? 'max-width-550px' : 'width-400px'}`}>
                <p className='text-xl text-bold pad-l-xxlg pad-t-lg pad-b-sm'>
                    3. Installation Status
                </p>
                <div className="flex align-center">
                    <p className="pad-l-xxlg pad-t-md"><b>Project has installation?:</b></p>
                    <p className={`pad-t-md pad-l-xs ${(installerArray.length > 0 || state?.project?.install_date?.length > 0) ? "text-green" : "text-black"}`}> {(installerArray.length > 0 || state?.project?.install_date?.length > 0) ? "Yes" : "No"}</p>
                </div>
                {installerArray?.length > 0 ? installerArray?.map((installer, index, array) => (
                    <div key={installer.id} className={`pad-b-md flex ${index === array?.length - 1 ? '' : 'underline-black margin-b-xlg'}`}>
                        <div className='flex-column width-50'>
                            <p className="pad-l-xxlg pad-t-sm">
                                <b>Installer:</b> {installer.installerName}</p>
                            {installer?.installDate?.length > 0 ?
                                <div className="flex pad-l-xxlg pad-t-xxlg column-gap-sm">
                                    <img src={calendarIcon} alt="calendar icon"
                                        className="bg-green2 border-radius-md pad-xs" />
                                    <p className="line-height-sm"><small><i>
                                        On {formatDate(installer.installDateUpdated).replace(/-/g, "/")} at {convertTimeStamp(installer.installDateUpdated)}, set installation date to {new Date(installer.installBadgeDate).toLocaleDateString('en-US')}.
                                    </i></small></p>
                                </div>
                                :
                                <div className="flex pad-l-xxlg pad-t-xxlg column-gap-sm">
                                    <img src={calendarIcon} alt="calendar icon"
                                        className="bg-green2 border-radius-md pad-xs" />
                                    <p><small><i>No notes</i></small></p>
                                </div>
                            }
                        </div>
                        <div className="flex-column pad-l-lg pad-t-sm">
                            <p><b>Install Date </b><small><i>(Estimate)</i></small>:</p>
                            <input className={`form-input pad-l-md ${(installerArray.length > 0) ? "" : " bg-gray"}`} type='date'
                                value={inputInstallDate[index]?.installDate}
                                id={installer.id}
                                onChange={handleDateChange}>
                            </input>
                            <button
                                id={installer.id}
                                className={`${installationDateChanged ? 'b-none box-shadow bg-light-green green-button text-white pointer' : 'btn-disabled'} margin-t-md border-radius-xs pad-md text-smaller bolder`}
                                disabled={!installationDateChanged}
                                onClick={(event) => handleSaveDate(event)}>
                                Save Installation Date
                            </button>
                            <button
                                id={installer.id}
                                className={`margin-t-md margin-b-xlg b1-blue border-radius-xs bolder box-shadow pad-md text-smaller
                    ${inputInstallDate[index]?.installDate === "" ? "disabled-btn" : "b2-blue bg-white text-blue pointer darken-background"} `}
                                onClick={displayEmailModal} disabled={inputInstallDate[index]?.installDate === ""}>
                                Notify Vendors
                            </button>
                        </div>
                    </div>
                ))
                    :
                    <>
                        <div className='flex'>
                            <div className='flex-column width-50'>
                                <p className="pad-l-xxlg pad-t-sm">
                                    <b>Installer: </b>No installers</p>
                                {state?.project?.install_date?.length > 0 ?
                                    <div className="flex pad-l-xxlg pad-t-xxlg column-gap-sm">
                                        <img src={calendarIcon} alt="calendar icon"
                                            className="bg-green2 border-radius-md pad-xs" />
                                        <p className="line-height-sm"><small><i>
                                            On {formatDate(state?.project?.install_date_updated).replace(/-/g, "/")} at {convertTimeStamp(state?.project?.install_date_updated)}, set installation date to {formatDate(state?.project?.installBadgeDate).replace(/-/g, "/")}.
                                        </i></small></p>
                                    </div>
                                    :
                                    <div className="flex pad-l-xxlg pad-t-xxlg column-gap-sm">
                                        <img src={calendarIcon} alt="calendar icon"
                                            className="bg-green2 border-radius-md pad-xs" />
                                        <p><small><i>No notes</i></small></p>
                                    </div>
                                }
                            </div>
                            <div className="flex-column pad-l-lg pad-t-sm">
                                <p><b>Install Date </b><small><i>(Estimate)</i></small>:</p>
                                <input className='form-input pad-l-md' type='date'
                                    value={inputInstallDate}
                                    id={state?.project?.project_id}
                                    onChange={handleDateChange}>
                                </input>
                                <button
                                    className={`${installationDateChanged ? 'b-none box-shadow bg-light-green green-button text-white pointer' : 'btn-disabled'} margin-t-md border-radius-xs pad-md text-smaller bolder`}
                                    disabled={!installationDateChanged}
                                    onClick={handleSaveDate}>
                                    Save Installation Date
                                </button>
                                <button
                                    title='Notify Vendors'
                                    id={state?.project?.project_id}
                                    className={`margin-t-md border-radius-xs text-bold box-shadow pad-md text-smaller
                ${inputInstallDate ? "b2-blue bg-white text-blue pointer darken-background " : "disabled-btn"}`}
                                    onClick={displayEmailModal} disabled={inputInstallDate === ""}>
                                    Notify Vendors
                                </button>
                            </div>
                        </div>
                    </>
                }
            </div>
            <div className={`${showEmailModal ? "email-modal border-radius-xs b-none" : "hide"}`}>
                <button onClick={handleLeaveModal} className='bg-white b-none close-button'>
                    <img src={xIcon} alt="close button" />
                </button>
                <div className='flex-column relative width-50'>
                    <p className="text-bold text-lrg pad-b-xs pad-t-xxlg pad-l-xxxlg">Vendors New Installation Date</p>
                    <hr className="width-100"></hr>
                    <p className="pad-t-xs pad-l-xxxlg">From: <b>ops@playgroundboss.com</b></p>
                    <p className="line-height-xs pad-l-xxxlg">Email Subject: <b>Installation Date</b></p>
                    <div className="pad-t-md pad-b-lg pad-l-xxxlg pad-r-xxxlg">
                        <p>Bcc Vendors:</p>
                        <div className='flex flex-wrap column-gap-md align-center b1-black border-radius-xs pad-xs'>
                            {vendorArray?.length > 0 ? vendorArray?.map((vendor) => (
                                <div className="flex align-center column-gap-sm" key={vendor.vendorID}>
                                    <input onChange={handleVendorCheckbox} type="checkbox" id={vendor.vendorID} name={vendor.vendorName} value={vendor.vendorEmail} className="sm-box pointer" defaultChecked />
                                    <label htmlFor={vendor.vendorID} name={vendor.vendorName} className="pointer">{vendor.vendorName}</label>
                                </div>
                            )) :
                                <p>No vendors</p>}
                        </div>
                    </div>
                    <div className='flex pad-t-md pad-b-xlg pad-l-xxxlg pad-r-xxxlg'>
                        <div className="flex-column width-40">
                            <p>Bcc Installer:</p>
                            {installerNameModal.length > 0 ?
                                <div className='flex column-gap-md align-center'>
                                    <input checked={sendToInstaller} type="checkbox" id="installer" name="installer" className="sm-box pointer" onChange={handleInstallerCheckbox} />
                                    <label htmlFor="installer" className="pointer">{installerNameModal}</label>
                                </div>
                                : <p>No installers</p>
                            }
                        </div>
                        <div className="flex-column width-60">
                            <p className="pad-b-xs">Bcc Other Email(s):</p>
                            <input type="email" id="bcc" name="bcc" multiple list="bccemailsvendors"
                                className="width-100" value={vendorBccField}
                                onChange={handleVendorBccField} />
                            <datalist id="bccemailsvendors">
                                {userDropdown?.map((user) => (
                                    <option key={user.userName} value={user.userEmail} />
                                ))}
                            </datalist>
                        </div>
                    </div>
                    <div className="flex-column pad-l-xxxlg pad-r-xxxlg ">
                        <p>Message:</p>
                        <textarea
                            className="b1-black border-radius-xs pad-xs no-resize"
                            rows="5"
                            value={vendorEmailMessage}
                            onChange={(e) =>
                                setVendorEmailMessage(e.target.value)} />
                        <p className='margin-t-md'>Signature:</p>
                        <textarea
                            className="b1-black border-radius-xs pad-xs overflow-y-hidden no-resize"
                            rows="5"
                            value={signature}
                            onChange={(e) => setSignature(e.target.value)} />
                        <button
                            className={`b-none border-radius-xs bolder box-shadow pad-l-60 pad-r-60 pad-md absolute bottom-right text-white
                    ${vendorEmailSent ? "bg-green2" : "bg-light-green green-button pointer"}`}
                            onClick={handleSendVendorEmail} disabled={vendorEmailSent}>
                            {vendorEmailSent ? "Email Sent!" : "Send Email"}
                        </button>
                    </div>
                </div>
                <hr className="flex-row"></hr>
                <div className='flex-column relative width-50'>
                    <p className="text-bold text-lrg pad-t-xxlg pad-b-xs pad-l-xxxlg">Email Customers New Installation Date</p>
                    <hr className="width-100"></hr>
                    <p className="pad-t-xs pad-l-xxxlg">From: <b>ops@playgroundboss.com</b></p>
                    <p className="line-height-xs pad-l-xxxlg">Email Subject: <b>Installation Date</b></p>
                    <div className='flex pad-t-md pad-b-xlg pad-r-xxxlg pad-l-xxxlg'>
                        <div className="flex-column width-40 ">
                            <p>To Customer:</p>
                            <div className='flex column-gap-md align-center'>
                                <input checked={emailCustomer} onChange={handleCustomerCheckbox} type="checkbox" id="customer" name="customer" className="sm-box pointer" />
                                <label htmlFor="customer" className="pointer">{contactName}</label>
                            </div>
                        </div>
                        <div className="flex-column width-60">
                            <p className="pad-b-xs">Bcc Other Email(s):</p>
                            <input type="email" id="bcc" name="bcc" multiple list="bccemailscustomer"
                                className="width-100" value={customerBccField}
                                onChange={handleCustomerBccField} />
                            <datalist id="bccemailscustomer">
                                {userDropdown?.map((user) => (
                                    <option key={user.userName} value={user.userEmail} />
                                ))}
                            </datalist>
                        </div>
                    </div>
                    <div className="flex-column pad-r-xxxlg pad-l-xxxlg">
                        <p>Message:</p>
                        <textarea
                            className="b1-black border-radius-xs pad-xs no-resize"
                            rows="5"
                            value={customerEmailMessage}
                            onChange={(e) =>
                                setCustomerEmailMessage(e.target.value)} />
                        <p className='margin-t-md'>Signature:</p>
                        <textarea
                            className="b1-black border-radius-xs pad-xs overflow-y-hidden no-resize"
                            rows="5"
                            value={signature}
                            onChange={(e) => setSignature(e.target.value)} />
                        <button
                            className={`b-none border-radius-xs bolder box-shadow pad-l-60 pad-r-60 pad-md absolute bottom-right text-white
                    ${customerEmailSent ? "bg-green2" : "bg-light-green green-button pointer"}`}
                            onClick={handleSendCustomerEmail} disabled={customerEmailSent}>
                            {customerEmailSent ? "Email Sent!" : "Send Email"}
                        </button>
                    </div>
                </div>
            </div>
            <div id="page-mask"
                className={`${showEmailModal ? "" : "hidden"}`}></div>
        </>
    )
}

export default InstallationStatus